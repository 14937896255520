<template>
  <transition-group
    class="flex justify-center w-full relative"
    :class="{'flex-wrap': wrap}"
    name="scale-up"
    appear
    tag="ul"
  >
    <li v-for="(radio, index) in radios" :key="index" class="cursor-pointer">
      <card-radio
        :value="radio.value"
        :selected="selected?.value == radio.value"
        @click="select(radio)"
      >
        <slot :radio="radio"></slot>
      </card-radio>
    </li>
  </transition-group>
</template>

<script>
import CardRadio from "./CardRadio.vue";
export default {
  components: { CardRadio },
  data() {
    return {
      selected: this.preselect >= 0 ? this.radios[this.preselect] : null,
    };
  },
  emits: ["update:value"],
  methods: {
    select(radio) {
      if (this.selected == radio) this.selected = null;
      else this.selected = radio;

      this.$emit("update:value", radio.value);
    },
  },
  props: {
    radios: Array,
    wrap: {
      type: Boolean,
      default: true,
    },
    preselect: {
      type: Number,
      default: null,
    },
  },
};
</script>