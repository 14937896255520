<template>
    <div class="bg-white w-auto h-auto p-4 my-2 rounded-3xl shadow-xl sm:hover:bg-orange-tint sm:hover:text-orange transition duration-300 ease-in-out mx-2" :class="{ 'bg-orange-tint text-orange shadow-none': selected }">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: String,
                required: true,
            },
            selected: {
                type: Boolean,
                default: false
            },
        },
        
    }
</script>